<template>
  <div v-if="loaded" class="padding-x-5-tablet">
    <div class="padding-xy-4 padding-x-0-tablet padding-y-5-tablet">
      <div class="columns is-multiline is-vcentered">
        <div class="column is-12-mobile">
          <b-field>
            <b-input
              v-model="searchText"
              placeholder="Search..."
              type="search"
              icon="search" />
          </b-field>
        </div>
        <div class="column is-narrow-tablet">
          <b-select v-model="searchLicensee" placeholder="Office" expanded>
            <option :value="null">
              All
            </option>
            <option v-for="office in offices" :key="office.id" :value="'office-' + office.id">
              {{ office.name }}
            </option>
          </b-select>
        </div>
        <div class="column is-narrow-tablet">
          <div class="field" style="line-height: 1">
            <b-checkbox v-model="showSuspended">
              Show Suspended
            </b-checkbox>
          </div>
        </div>
        <div class="column is-narrow-tablet">
          <b-button
            class="has-text-grey"
            @click="generateCsv">
            Export CSV
          </b-button>
        </div>
      </div>
    </div>

    <b-table
      :data="licensees"
      class="scrollable clickable-row"
      :mobile-cards="false"
      :hoverable="true"
      default-sort="name"
      @click="goToLicensee">
      <b-table-column v-slot="props" field="status" label="" :visible="showSuspended" width="10">
        <b-icon v-if="props.row.suspended" icon="ban" size="is-small" type="is-danger" />
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Licensee" sortable>
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="officeId" label="Office" sortable>
        <div v-if="props.row.officeId">
          {{ officesById[props.row.officeId].name }}
        </div>
      </b-table-column>
      <b-table-column v-slot="props" field="code" label="Code #" sortable>
        {{ props.row.code }}
      </b-table-column>
      <b-table-column v-slot="props" field="billingCycle" label="Billing" sortable>
        {{ props.row.billingCycle }}
      </b-table-column>
    </b-table>
    <div class="padding-y-4" />
  </div>
</template>

<script>
import Moment from 'moment'
import PapaParse from 'papaparse'
import RandomString from 'randomstring'
import ReportsApi from '@/apis/ReportsApi'

export default {
  name: 'Licensees',
  data () {
    return {
      loaded: false,
      searchText: null,
      searchLicensee: null,
      showSuspended: false
    }
  },
  computed: {
    licensees () {
      let licensees = this.$store.state.licensees

      if (this.searchText) {
        let searchText = this.searchText.toLowerCase()

        licensees = licensees.filter(licensee => {
          let searchFull = `${licensee.name} ${licensee.code}`.toLowerCase()

          return searchFull.includes(searchText)
        })
      }

      if (this.searchLicensee) {
        licensees = licensees.filter(licensee => {
          return `office-${licensee.officeId}` === this.searchLicensee
        })
      }
      if (!this.showSuspended) {
        licensees = licensees.filter(licensee => {
          return licensee.suspended === 0
        })
      }

      return licensees
    },
    offices () {
      return this.$store.state.offices
    },
    officesById () {
      let mapped = {}
      this.offices.forEach(office => {
        mapped[office.id] = office
      })
      return mapped
    },
    connections () {
      return this.$store.state.connectionsLight
    },
    licenseeConnections () {
      return this.$store.state.licenseeConnections
    }
  },
  async mounted () {
    await this.$store.commit('setTitle', 'Licensees')
    await this.$store.dispatch('refreshLicensees')
    await this.$store.dispatch('refreshOffices')
    await this.$store.dispatch('refreshConnectionsLight', { hasImages: false, hasSizes: false, hasWeights: false })
    await this.$store.dispatch('refreshLicenseeConnections')

    this.loaded = true
  },
  methods: {
    goToLicensee (licensee) {
      this.$router.push({
        name: 'manage_licensee',
        params: { id: licensee.id }
      })
    },
    async generateCsv () {
      let data = this.licensees.map(licensee => {
        let officeName = this.offices.find(office => office.id === licensee.officeId)
        var flatColumns = [
          licensee.id,
          licensee.name,
          officeName.name,
          // licensee.licenseeNumber,
          licensee.code,
          licensee.billingCycle,
          licensee.taxWithholdingPercent
          // licensee.address,
          // licensee.latitude,
          // licensee.longitude,
          // licensee.primaryContact,
          // licensee.contactEmail,
          // licensee.officePhone,
          // licensee.officeFax,
          // licensee.website
        ]

        this.connections.map(connection => {
          var licenseeConnection = this.licenseeConnections.find(licenseeConnection => licenseeConnection.licenseeId === licensee.id && licenseeConnection.connectionId === connection.id)

          if (licenseeConnection) {
            // Connection Manufacture
            if (licenseeConnection.manufacture) {
              var pinBox = []
              pinBox.push(licenseeConnection.manufacturePin ? 'Pin' : null)
              pinBox.push(licenseeConnection.manufactureBox ? 'Box' : null)
              flatColumns.push(pinBox.join(' '))
            } else {
              flatColumns.push(null)
            }
            // Connection Repair
            if (licenseeConnection.repair) {
              pinBox = []
              pinBox.push(licenseeConnection.repairPin ? 'Pin' : null)
              pinBox.push(licenseeConnection.repairBox ? 'Box' : null)
              flatColumns.push(pinBox.join(' '))
            } else {
              flatColumns.push(null)
            }
            // Connection Accessory
            if (licenseeConnection.accessory) {
              pinBox = []
              pinBox.push(licenseeConnection.accessoryPin ? 'Pin' : null)
              pinBox.push(licenseeConnection.accessoryBox ? 'Box' : null)
              flatColumns.push(pinBox.join(' '))
            } else {
              flatColumns.push(null)
            }
            // Connection Technical Docs
            pinBox = []
            pinBox.push(licenseeConnection.techDocsPin ? 'Pin' : null)
            pinBox.push(licenseeConnection.techDocsBox ? 'Box' : null)
            flatColumns.push(pinBox.join(' '))
            // Connection Inspection Cheets
            pinBox = []
            pinBox.push(licenseeConnection.inspSheetsPin ? 'Pin' : null)
            pinBox.push(licenseeConnection.inspSheetsBox ? 'Box' : null)
            flatColumns.push(pinBox.join(' '))
          } else {
            flatColumns.push(null)
            flatColumns.push(null)
            flatColumns.push(null)
            flatColumns.push(null)
            flatColumns.push(null)
          }
        })

        return flatColumns
      })

      var fields = ['ID', 'Name', 'Office', 'Code #', 'Billing Cycle', 'Tax withholding']

      // Columns Connections Name
      this.connections.map(connection => {
        fields.push(connection.name + ' M')
        fields.push(connection.name + ' R')
        fields.push(connection.name + ' A')
        fields.push(connection.name + ' T')
        fields.push(connection.name + ' I')
      })

      let csv = PapaParse.unparse({
        fields: fields,
        data: data
      })

      let slug = RandomString.generate(20)

      var fileDate = Moment(new Date())
      await ReportsApi.saveReport(slug, 'text/csv;charset=utf-8', 'licensees_' + fileDate + '.csv', csv)

      document.location.href = `${process.env.VUE_APP_API_BASE}/api/v1/reports/${slug}/download`
    }
  }
}
</script>
