import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async saveReport (slug, contentType, filename, data) {
    const response = await Axios.post(url(`/api/v1/reports/${slug}`), {
      contentType: contentType,
      filename: filename,
      data: data
    }, options())

    return response.data
  },
  async getReportData (slug) {
    const response = await Axios.get(url(`/api/v1/reports/${slug}/data`), options())

    return response.data
  }
}
